import React from "react";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../components/head.js";

import Layout from "../components/layout";

const Ocean = () => {
  return (
    <Layout>
      <Head title="Santa Claus" />
      <h3>Santa Claus</h3>
      <p>I'm burning 🔥 a santa suit 🎅 down before they ever get it on me.</p>

      <p>would I ever wear a santa suit? </p>

      <p>you will never see me in a santa suit 🎅. I will burn it down 🔥</p>

      <p>
        is that it? no, I would take the ashes, and rub it in their faces. The
        faces of the audience, the faces of the Target shoppers.
      </p>

      <p>
        I would say, "feel this for once, you frauds! you gray/stone 🪦 faced
        liars! look at your false mythology 🦄 now, in tatters! you idiots! you
        hypocrites!
      </p>

      <p>WHAT WOULD JESUS ✝️ HAVE DONE </p>

      <p>WHAT WOULD JESUS ✝️ HAVE DONE AT THE STEPS OF THE TEMPLE 🏛️</p>

      <p>THE TEMPLE OF MC-MACYS MANSION ESTATE EMPORIUM ⛪️</p>

      <p>THE TEMPLE OF UNCLE SAM WALMART SAFEWAY WHOLE FOODS ⛪️</p>

      <p>YOU HYPOCRITES. YOU SCOUNDRELS. 🎆</p>

      <p>I BRING NOT A SWORD 🗡️, BUT A SHOVEL 🔨</p>

      <p>A SHOVEL FOR YOUR GRAVES ⚰️</p>

      <p>I DID NOT PLAY YOU, YOU PLAYED YOURSELF. 🔮</p>

      <p>I DID NOT KILL YOU, YOU KILLED YOURSELF. YOU NEVER LIVED. 🧟</p>

      <p>YOU IDIOTS! YOUR GUISE OF AMERICAN NORMALCY 🇺🇸 DOES NOT ILLUSE ME</p>

      <p>YOUR REALITY TV 📺 BULLSHIT,</p>

      <p>I KNOW WHAT YOU ARE. FUCK YOU</p>

      <p>FUCK YOU AND YOUR LIFESTYLE</p>

      <p>
        FUCK YOU AND THE CAR 🚗 YOU DROVE IN ON, THE CLOTHES 👕 YOU WEAR, THE
        HOUSE 🏘️ YOU LIVE IN. I DON'T GIVE A FUCK. FUCK YOU
      </p>

      <p>I will not be wearing a santa suit this christmas (2022)</p>
    </Layout>
  );
};

export default Ocean;
